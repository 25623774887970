<template>
  <div class="home">
    <h1>POLLS</h1>
    <p>{{ $route.params.serverName }}</p>

    <p>{{ $route.params.webAppName }}</p>

    <p>{{ $route.params.pollName }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PollsView",
});
</script>
