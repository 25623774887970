<template>
  <div class="home">
    <h1>WEBAPPS EVEN MORE IMPROVED</h1>
    <p>{{ $route.params.serverName }}</p>
    <p>{{ $route.params.webAppName }}</p>

    <div v-if="jsonData">
      <h2>JSON Data</h2>
      <pre>{{ jsonData }}</pre>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "WebAppsView",
  data() {
    return {
      jsonData: null,
    };
  },
  mounted() {
    // Assuming you're serving the JSON file from the same server
    fetch("/webapps/dizplai.json")
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        this.jsonData = data;
      })
      .catch((error) => {
        console.error("Error fetching JSON file:", error);
      });
  },
});
</script>
